import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// Dynamic imports
const MobileCards = dynamic(() => import('./Mobile'));
const DesktopCards = dynamic(() => import('./Desktop'));

const ArticleCards = ({ item, location }) => {
  const s = require('./articles.module.scss');
  return (
    <div className={s.Wrapper}>
      <div className={s.Mobile}>
        <MobileCards {...item} location={location} />
      </div>
      <div className={s.Desktop}>
        <DesktopCards {...item} location={location} />
      </div>
    </div>
  );
};

ArticleCards.propTypes = {
  item: PropTypes.object,
  location: PropTypes.string,
};

export default ArticleCards;
